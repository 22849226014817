import { Grid, Hidden, Typography } from '@mui/material';
import { MiniDrawer } from './MiniDrawer';
import CoypuLogo from '../mappings/searchMappings/CoypuLogo';
import sandbox_2 from '../..//../assets/img/sandbox_logos/sandbox_2.svg';
import { RateMe } from './buttons/RateMe';
import { FileIssue } from './buttons/FileIssue';
import { ReleaseInfo } from './buttons/ReleaseInfo';
import { makeStyles } from '@mui/styles';
import { useLocation } from 'react-router';
import { Stars } from './buttons/Stars';
import { Nfdi4ingLogo } from '../webprotege/Nfdi4ingLogo';
import HowToUse from './buttons/HowToUse';
import { FairDSLogo } from '../word2vec/FairDSLogo';
import Nfdi4Energy from '../pid/Nfdi4EnergyLogo';
import Nfdi4EnergyLogo from "../pid/Nfdi4EnergyLogo";

const useStyles = makeStyles((theme) => ({
  image: {
    width: '100%',
    height: '12%',
  },
}));

const pathsWithoutButtons = ['/ts/sandbox/release', '/ts/sandbox/funding'];

const SandboxLayout = ({ children }) => {
  const location = useLocation();
  const classes = useStyles();

  const hideButtons = pathsWithoutButtons.includes(location.pathname);
  const showSpecificButtons = location.pathname === '/ts/sandbox';

  const webprotege = location.pathname === '/ts/sandbox/webprotege';
  const annotation = location.pathname === '/ts/sandbox/annotation';
  const semanticSearch = location.pathname === '/ts/sandbox/semanticsearch';
  const mapping = location.pathname === '/ts/sandbox/mappingTableView' || location.pathname === '/ts/sandbox/mappingGraphView' || location.pathname === '/ts/sandbox/generatemapping';
  const semanticdiff = location.pathname === '/ts/sandbox/semanticdiff' || location.pathname === '/ts/sandbox/semanticdiff/add';
  const pidChecker = location.pathname === '/ts/sandbox/pidChecker'
  const gitOntologyStatusCheck = location.pathname === '/ts/sandbox/gitOntologyStatusCheck'

  let url = '';
  let extension = '';

  if (mapping) {
    extension = 'mappings';
    url = 'https://docs.google.com/forms/d/1rPfhm_Dot-E3LsZW-8vRpQlD9wqnm8bUIgM5sPT2Nvg/viewform';
  } else if (annotation) {
    extension = 'annotation';
    url = 'https://docs.google.com/forms/d/e/1FAIpQLSfuHyQ-c474MEkNDmV9n5kgUXgVFxklr4Em7iVAeekm7_ZBtg/viewform';
  } else if (semanticSearch) {
    extension = 'semanticSearch';
    url = 'https://docs.google.com/forms/d/1hLAxQfzufmSBYUBrsna7Yv-7BkIM0FPWBAcfneJkogE/viewform';
  } else if (webprotege) {
    extension = 'webprotege';
    url = 'https://docs.google.com/forms/d/1aF-qUfEIt008zKSmoU-M-fgMNc0LBFkMXuBFUrvMfaM/viewform';
  } else if (semanticdiff) {
    extension = 'semanticdiff';
    url = 'https://docs.google.com/forms/d/1vVI1B1T66aYpOg_0LTEyN9JKv35paEW5udNMzaZ66HY/viewform';
  } else if (pidChecker) {
      extension = 'pidChecker';
      url = '';
  } else if (gitOntologyStatusCheck) {
      extension = 'gitOntologyStatusCheck';
      url = '';
  } else  {
    extension = 'pairwiseSimilarity';
    url = 'https://docs.google.com/forms/d/1aF-qUfEIt008zKSmoU-M-fgMNc0LBFkMXuBFUrvMfaM/viewform';
  }

  return (
    <div style={{ display: 'flex' }}>
      <Hidden smUp>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '100%',
            width: '100%',
            minHeight: '79.5vh',
            marginTop: '1rem',
            padding: '2rem',
          }}
        >
          <Typography variant="h5" color="textSecondary">
            This page is not available in mobile version.
          </Typography>
        </div>
      </Hidden>
      <Hidden smDown>
        <MiniDrawer />
        <Grid container>
          <Grid
            item
            md={10.5}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: location.pathname === '/ts/sandbox' || location.pathname === '/ts/sandbox/funding' ? 'center' : null,
              alignItems: 'center',
              minHeight: '85vh',
              padding: location.pathname === '/ts/sandbox/webprotege' || location.pathname === '/ts/sandbox/semanticSearch' ? 0 : '2rem',
            }}
          >
            {children}
          </Grid>
          <Grid item md={1.5} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, gap: '5px' }}>
              {showSpecificButtons ? <img src={sandbox_2} alt="erstellen" className={classes.image} /> : null}
              {!hideButtons && <FileIssue />}
              {!hideButtons && !showSpecificButtons && <HowToUse />}
              {!hideButtons && !showSpecificButtons && <ReleaseInfo extension={extension} />}
              {!hideButtons && !showSpecificButtons && semanticdiff && <CoypuLogo />}
              {!hideButtons && !showSpecificButtons && mapping && <CoypuLogo />}
              {!hideButtons && !showSpecificButtons && webprotege && <Nfdi4ingLogo />}
              {!hideButtons && !showSpecificButtons && annotation && <Nfdi4ingLogo />}
              {!hideButtons && !showSpecificButtons && semanticSearch && <FairDSLogo />}
              {!hideButtons && !showSpecificButtons && pidChecker && <Nfdi4EnergyLogo />}
                {!hideButtons && !showSpecificButtons && gitOntologyStatusCheck && <Nfdi4EnergyLogo />}
            </div>
            {!hideButtons && !showSpecificButtons && url !=='' && <RateMe url={url} />}
            {mapping && <Stars url="https://docs.google.com/forms/d/e/1FAIpQLSf3xi-HoWoWkfeDpzfLSSHeVB1k-7zWowASxQBEc06bpYDT5g/formResponse?usp=pp_url" ratingEntry="717851789" tool="Mappings" />}
            {webprotege && <Stars url="https://docs.google.com/forms/d/e/1FAIpQLSd4xKEhm_UaCGgiv3uUjWLdrqXh1pxuXvjjjiTLjVGfUoq_kg/formResponse?usp=pp_url" ratingEntry="717851789" tool="WebProtege" />}
            {annotation && (
              <Stars url="https://docs.google.com/forms/d/e/1FAIpQLSfEc3iqzMsNfBq8Iw4mB3fbJwJ6MTQm53OO2JzyYk8iP9UA5g/formResponse?usp=pp_url" ratingEntry="717851789" tool="Annotation Service" />
            )}
            {semanticdiff && (
              <Stars url="https://docs.google.com/forms/d/e/1FAIpQLSd21OGVgszTRJ-zHisdKfwxUEMwXMbyGqzfIHOADZbANnKC8w/formResponse?usp=pp_url" ratingEntry="717851789" tool="semanticdiff" />
            )}
            {semanticSearch && (
              <Stars url="https://docs.google.com/forms/d/e/1FAIpQLScdB-7aY9tVJyr2zrSzOLmNf32gVCKMDN1pxSLx6pjx8Y3dTg/formResponse?usp=pp_url" ratingEntry="717851789" tool="Semantic Search" />
            )}
          </Grid>
        </Grid>
      </Hidden>
    </div>
  );
};

export default SandboxLayout;
